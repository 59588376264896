export const Quad = {
    vertices: [
        -1.0, 1.0, 0.0,
        -1.0, -1.0, 0.0,
        1.0, 1.0, 0.0,
        1.0, -1.0, 0.0,
    ],
    indices: [
        0, 1, 2, 2, 1, 3
    ],
};